<template>
  <section
    class="
      bg-white
      w-full
      h-auto
      flex
      md:flex-row
      flex-col
      justify-between
      pr-5
      pl-5
      md:pl-115 md:pr-115
      pt-16
      md:pt-24
      pb-118
    "
  >
    <div
      data-aos="fade-right"
      data-aos-duration="1000"
      class="heading w-full text-primarydark"
    >
      <h3
        class="
          font-bold
          text-3xl
          md:text-4xl
          leading-124
          md:text-left
          text-center
          md:max-w-md
        "
      >
        More Payment Options, More Distribution
      </h3>
      <p
        class="
          leading-160
          text-xl
          md:text-left
          text-center
          md:text-2xl
          tracking-0.04em
          mt-9
          max-w-lg
        "
      >
        PelPay provide your customers with the flexibility of multiple payment
        options
      </p>

      <div class="bullet-point mt-78">
        <div class="bp-1 mb-12 flex justify-start items-center">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="17.5222" cy="18.2992" r="17.5222" fill="#EAFAF3" />
            <path
              d="M26.2834 12.9452L14.2368 24.6267L8.76111 19.3164"
              stroke="#2DCA8C"
              stroke-width="2.92037"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span
            class="block ml-4 text-lg leading-5 font-medium text-pelpayblack"
            >Card Payments ( Visa, MasterCard, AMEX, union pay+)</span
          >
        </div>

        <div class="space-x grid grid-cols-2 items-center gap-y-9">
          <div class="bp-1 flex justify-start items-center">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5222" cy="18.2992" r="17.5222" fill="#EAFAF3" />
              <path
                d="M26.2834 12.9452L14.2368 24.6267L8.76111 19.3164"
                stroke="#2DCA8C"
                stroke-width="2.92037"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              class="block ml-4 text-lg leading-5 font-medium text-pelpayblack"
              >Bank Account</span
            >
          </div>
          <div class="bp-1 flex justify-start items-center">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5222" cy="18.2992" r="17.5222" fill="#EAFAF3" />
              <path
                d="M26.2834 12.9452L14.2368 24.6267L8.76111 19.3164"
                stroke="#2DCA8C"
                stroke-width="2.92037"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              class="block ml-4 text-lg leading-5 font-medium text-pelpayblack"
              >Virtual Account</span
            >
          </div>
          <div class="bp-1 flex justify-start items-center">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5222" cy="18.2992" r="17.5222" fill="#EAFAF3" />
              <path
                d="M26.2834 12.9452L14.2368 24.6267L8.76111 19.3164"
                stroke="#2DCA8C"
                stroke-width="2.92037"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              class="block ml-4 text-lg leading-5 font-medium text-pelpayblack"
              >Virtual Cards</span
            >
          </div>
          <div class="bp-1 flex justify-start items-center">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5222" cy="18.2992" r="17.5222" fill="#EAFAF3" />
              <path
                d="M26.2834 12.9452L14.2368 24.6267L8.76111 19.3164"
                stroke="#2DCA8C"
                stroke-width="2.92037"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              class="block ml-4 text-lg leading-5 font-medium text-pelpayblack"
              >QR Code</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      class="paymenoption-image w-full mt-20 md:mt-0 md:ml-10"
    >
      <img class="w-full" src="../../assets/images/paymentoption.png" alt="" />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
