<template>
  <section>
    <FeaturesHero />
    <FeaturesServices />
  </section>
</template>

<script>
import FeaturesHero from "../components/Features/FeaturesHero.vue";
import FeaturesServices from "../components/Features/FeaturesServices.vue";
export default {
  components: { FeaturesHero, FeaturesServices },
};
</script>

<style></style>
