<template>
  <section class="z-50 pb-4 about-hero bg-white">
    <div
      class="
        top-header
        w-fulls
        pt-20
        md:pt-103px
        pb-16
        bg-pelpayteal100
        md:w-9/12 md:max-w-5xls
        pr-5
        pl-5
        md:pl-115
      "
    >
      <h3
        class="
          font-bold
          text-5xl
          md:text-64px
          text-pelpayblack100
          leading-tight
          md:leading-80px md:max-w-4xl
          text-left
        "
      >
        Helping businesses digitize their payment processes
      </h3>
      <p
        class="
          text-lg text-primarydark
          leading-30px
          font-medium
          text-left
          max-w-2xl
          mt-8
        "
      >
        Pelpay is a product of ChamsSwitch Limited. ChamsSwitch is a financial
        payment solutions and transactions switching company on a mission to
        help businesses in Africa digitize their payment process with ease.
      </p>
    </div>
    <div
      class="
        hero-image
        h-56
        md:h-auto
        mx-auto
        w-full
        bg-no-repeat bg-cover bg-bottom
      "
      :style="{
        'background-image':
          'url(' + require('../../assets/images/featureshero.png') + ')',
      }"
    >
      <!--      <img src="../../assets/images/featureshero.png" alt="" />-->
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
