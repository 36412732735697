<template>
  <section class="border-t border-b md:px-32 md:mb-16 pb-20 md:pb-0">
    <div
      class="
        flex-cont flex
        md:flex-row
        flex-col
        justify-between
        md:max-w-6xl
        mx-auto
      "
    >
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="01 flex pt-62 pb-8 space-x-5 px-10 md:px-0 pr-10"
      >
        <span class="blocks text-pelpaygreen opacity-50 font-bold text-288px"
          >01</span
        >

        <div class="desc">
          <h4 class="heading text-2xl font-bold text-pelpayblack">
            Team of experts
          </h4>
          <p
            class="text-pelpayblack100 mt-2 max-w-250px text-base leading-27px"
          >
            A team of experts passionate about product management
          </p>
        </div>
      </div>

      <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        class="01 flex md:border pt-62 pb-8 px-10 space-x-5"
      >
        <div>
          <span
            class="block text-pelpaygreen opacity-50 font-bold text-288px -mt-1"
            >0</span
          >
          <span
            class="block text-pelpaygreen opacity-50 font-bold text-288px -mt-4"
            >2</span
          >
        </div>

        <div class="desc">
          <h4 class="heading text-2xl font-bold text-pelpayblack">
            Friendly Consultants
          </h4>
          <p
            class="text-pelpayblack100 mt-2 max-w-300px text-base leading-27px"
          >
            A team of experts passionate about product management
          </p>
        </div>
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="01 flex pt-62 pb-8 pl-10 space-x-5"
      >
        <div>
          <span
            class="block text-pelpaygreen opacity-50 font-bold text-288px -mt-1"
            >0</span
          >
          <span
            class="block text-pelpaygreen opacity-50 font-bold text-288px -mt-4"
            >3</span
          >
        </div>

        <div class="desc">
          <h4 class="heading text-2xl font-bold text-pelpayblack">
            Best Solutions
          </h4>
          <p
            class="text-pelpayblack100 mt-2 max-w-300px text-base leading-27px"
          >
            A team of experts passionate about product management
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
